<ion-header class="modal-header">
  <div class="modal-title">
    <h3>Manage Tags for {{ objectIds.length }} Item{{ objectIds.length === 1 ? '' : 's' }}</h3>
  </div>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="clear" shape="round" class="close-button" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid style="margin: 10px 5px 0 5px">
    <ion-row>
      <ion-col size="6">
        <ion-button color="dark" expand="block" [fill]="isAddMode() ? 'solid' : 'outline'" (click)="setAddMode()">
          <ion-icon name="add-outline" class="padded-icon"></ion-icon>
          Add tag
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button expand="block" color="dark" [fill]="isRemoveMode() ? 'solid' : 'outline'" (click)="setRemoveMode()">
          <ion-icon name="close-outline" class="padded-icon"></ion-icon>
          Remove existing tag
        </ion-button>
      </ion-col>
      <ion-col size="12">
        <ion-chip
          mode="ios"
          class="select-outline"
          [outline]="true"
          (click)="viewTags()"
          style="height: 40px; width: 100%; margin: 10px 0"
        >
          <ion-label>
            <ion-icon name="pricetag" class="padded-icon"></ion-icon>
            <span *ngIf="selectedTag">{{ selectedTag.name }}</span>
            <span *ngIf="!selectedTag">Select a tag...</span>
          </ion-label>
        </ion-chip>
      </ion-col>
      <ion-col size="12">
        <ion-button color="success" (click)="submit()" [disabled]="!selectedTag">
          <ion-icon name="checkmark-outline" class="padded-icon" [hidden]="isLoading"></ion-icon>
          <ion-spinner name="crescent" [hidden]="!isLoading"></ion-spinner>
          Update
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
