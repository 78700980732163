import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Tag } from 'src/models';
import { MessageService } from 'src/services/message.service';
import { SearchablePopoverService } from 'src/services/searchable-popover.service';

@Component({
  selector: 'app-edit-tag-links',
  templateUrl: './edit-tag-links.component.html',
  styleUrls: ['./edit-tag-links.component.scss'],
})
export class EditTagLinksComponent {
  isLoading = false;
  mode: 'add' | 'remove' = 'add';
  selectedTag: Tag;
  objectIds: string[];
  addCallback: (tagId: string, objectIds: string[]) => Promise<void>;
  removeCallback: (tagId: string, objectIds: string[]) => Promise<void>;

  constructor(
    private modalCtrl: ModalController,
    private msgSrvc: MessageService,
    private searchablePopoverService: SearchablePopoverService,
  ) {}

  isAddMode() {
    return this.mode === 'add';
  }

  isRemoveMode() {
    return this.mode === 'remove';
  }

  setAddMode() {
    this.mode = 'add';
  }

  setRemoveMode() {
    this.mode = 'remove';
  }

  async viewTags() {
    this.searchablePopoverService.tags({
      event,
      callback: async (id: string, name: string, object: Tag) => {
        this.selectedTag = object;
      },
    });
  }

  async submit() {
    if (!this.selectedTag) {
      await this.msgSrvc.show('Whoops! Please select a tag.');

      return;
    }

    this.isLoading = true;

    try {
      if (this.mode === 'add') {
        await this.addCallback(this.selectedTag.id, this.objectIds);
      } else {
        await this.removeCallback(this.selectedTag.id, this.objectIds);
      }

      this.dismiss();
    } catch (error) {
      await this.msgSrvc.showError(error);
    } finally {
      this.isLoading = false;
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
